import { useQuery } from "@tanstack/react-query"
import { apiClient } from "../../../api-client"

export const useUserQuery = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const { data } = await apiClient.get("user")
      return data
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
