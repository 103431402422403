// get statics

import { apiClient } from "../../api-client"

export class StaticApi {
  static async getStatics() {
    const { data } = await apiClient.get("statistics")

    return data.data
  }

  static async getPatientGroupedByGender(year) {
    const { data } = await apiClient.get("users-per-month", {
      params: { year: year },
    })

    return data.data
  }
}
