import { Navigate } from "react-router-dom"
import { useUserFromStore } from "../hooks/use-user-from-store"

/**
 *
 * @param {import('react').PropsWithChildren<{
 *   children: React.ReactNode
 *   fallback?: React.ReactNode
 * }>} props
 * @returns {import('react').ReactNode}
 */
export const Auth = ({ children, fallback }) => {
  const user = useUserFromStore()

  console.log("auth", user)

  if (!user) {
    return fallback || <Navigate to="/login" />
  }

  return <>{children}</>
}
