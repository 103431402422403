import Header from "../components/Header"
import Sidebar from "../components/Sidebar"

/**
 * @type {import("react").FC<import("react").PropsWithChildren>}
 */
export const DashboardLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Sidebar
        id="menu-item1"
        id1="menu-items1"
        activeClassName="doctor-list"
      />

      <div className="page-wrapper">{children}</div>
    </>
  )
}
