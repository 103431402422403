import { apiClient } from "../../api-client"

export class ContactApi {
  static async getContactList(params) {
    const { data } = await apiClient.get("customer-issues", {
        params,
      })

    return data
  }

  static async getContactById(id) {
    const { data } = await apiClient.get(`customer-issues/${id}`)

    return data.data
  }

  static async createContact(data) {
    const response = await apiClient.post("customer-issues", data)

    return response.data
  }

  static async updateContact(id, data) {
    const response = await apiClient.put(`customer-issues/${id}`, data)

    return response.data
  }

  static async deleteContact(id) {
    const response = await apiClient.delete(`customer-issues/${id}`)

    return response.data
  }
  static async deleteContactMany(ids) {
    const response = await apiClient.delete("customer-issues", {
      data: { ids },
    })

    return response.data
  }
}