import { apiClient } from "../../api-client"

export class PatientApi {
  static async getPatientList(params = {}) {
    const { data } = await apiClient.get("patients", {
      params,
    })
    return data
  }

  static async getPatientById(id) {
    const { data } = await apiClient.get(`patients/${id}`)

    return data.data
  }

  static async createPatient(data) {
    const response = await apiClient.post("patients", data)

    return response.data
  }

  static async updatePatient(id, data) {
    const response = await apiClient.put(`patients/${id}`, data)

    return response.data
  }

  static async deletePatient(id) {
    const response = await apiClient.delete(`patients/${id}`)

    return response.data
  }
  static async deletePatientMany(ids) {
    const response = await apiClient.delete("patients", {
      data: { ids },
    })

    return response.data
  }
}
